import { useEffect, useRef, useState } from "react";

const useCounter = (finalValue, milliseconds) => {
  const [count, setCount] = useState(0);
  const rawCount = useRef(0);
  const msInc = 10;
  const steps = milliseconds / msInc;
  const inc = finalValue / steps;

  useEffect(() => {
    if (count >= finalValue) {
      setCount(finalValue);
      return;
    }

    const interval = setInterval(() => {
      rawCount.current += inc;
      const newValue = Math.trunc(rawCount.current);

      if (newValue >= finalValue) {
        setCount(finalValue);
      } else if (newValue > count) {
        setCount(newValue);
      }
    }, msInc);

    return () => clearInterval(interval);
  }, [count, finalValue, inc, rawCount]);

  return count;
};

export default useCounter;

/*
const useCounter = (finalValue, milliseconds) => {
  const [count, setCount] = useState(0)

  const msInc = 10
  const steps = milliseconds / msInc
  const inc = finalValue / steps

  useEffect(() => {
    if (count >= finalValue) {
      setCount(finalValue)
      return
    }
    setTimeout(() => {
      const newValue = Math.ceil(count+inc)
      if (newValue >= finalValue) {
        setCount(finalValue)
      } else {
        setCount(newValue)
      }
    }, msInc)
  }, [count])

  return count
}*/
