import styled from "styled-components";
import {
  BP,
  Rem,
  Font,
} from "../../commons/Theme";

import { StyledTitle } from "../globalstyle";

export const StyledCounter = styled.div`
  margin-top: ${Rem(24)};
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;

  @media (${BP.ipad}) {
    padding-top: 0;
    margin-top: ${Rem(60)};
  }
  
  ${StyledTitle} {
    font-family: ${Font.babas};
  }
`;
