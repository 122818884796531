import styled, { css } from "styled-components";
import styledMap from "styled-map";
import {
  Rem, Colors, BP, Font,
} from "../commons/Theme";

export const StyledImage = styled.img`

`;
export const StyledTitleHero = styled.h1`
  text-transform: uppercase;
  font-size: ${styledMap`
    big: ${Rem(40)};
    small: ${Rem(28)};
    default: ${Rem(32)};
  `};
  line-height: ${Rem(50)};
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}

  @media (${BP.tablet}) {
    font-size: ${styledMap`
      big: ${Rem(56)};
      small: ${Rem(36)};
      default: ${Rem(40)};
    `};
    line-height: ${Rem(60)};
  }

  em {
    font-style: normal;
    color: ${Colors.yellow};
  }
`;

export const CssLi = css`
list-style-type: none !important;
  position: relative;
  padding-bottom: ${Rem(15)}; 
  padding-left:${Rem(25)}; 
  text-align: justify;
  font-size: ${styledMap`
  big: ${Rem(24)};
  small: ${Rem(16)};
  default: ${Rem(20)};
  mini: ${Rem(16)};
  `};
  font-weight: 300;
  line-height: ${styledMap`
    big: ${Rem(30)};
    small: ${Rem(22)};
    mini: ${Rem(18)};
    default: ${Rem(26)};
  `};

  @media (${BP.tablet}) {
    font-size: ${styledMap`
      big: ${Rem(30)};
      small: ${Rem(20)};
      default: ${Rem(25)};
      mini: ${Rem(16)};
    `};
    line-height: ${styledMap`
      big: ${Rem(38)};
      small: ${Rem(28)};
      default: ${Rem(32)};
      mini: ${Rem(18)};
    `};
  }

  &:before {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url('/2020/svg/new/singlebee3bee.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 5px;
    left: 0px;
  }

  & b {
    font-weight: bold;
  }
`;

export const StyledLi = styled.li`
  ${CssLi}
`;

export const SectionDivider = styled.div`
  height: 5.5rem;
  
  @media (${BP.tablet}) {
    height: 9.5rem;
 }
`;

export const StyledTitle = styled.h2`
  font-size: ${styledMap`
    big: ${Rem(38)};
    small: ${Rem(28)};
    mini: ${Rem(20)};
    default: ${Rem(32)};
  `};

  ${({ font }) => font ? `font-family: ${Font[font]};` : `font-family: ${Font.sans};`}

  line-height: ${styledMap`
    big: ${Rem(50)};
    small: ${Rem(45)};
    mini: ${Rem(22)};
    default: ${Rem(45)};
  `};
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}
  
  @media (${BP.tablet}) {
    font-size: ${styledMap`
      big: ${Rem(50)};
      small: ${Rem(36)};
      mini:  ${Rem(20)};
      default: ${Rem(40)};
    `};
    line-height: ${styledMap`
      big: ${Rem(62)};
      small: ${Rem(62)};
      mini:  ${Rem(22)};
      default: ${Rem(62)};
    `};
  }

  em {
    font-style: normal;
    color: ${Colors.yellow};
  }

  > a {
    text-decoration: none;
    ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}
  }

`;

export const StyledSubTitle = styled.h2`
  font-weight: 200;
  font-size: ${styledMap`
    big: ${Rem(32)};
    small: ${Rem(24)};
    default: ${Rem(28)};
  `};

  line-height: ${styledMap`
    big: ${Rem(34)};
    small: ${Rem(26)};
    default: ${Rem(30)};
  `};
  
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}

  @media (${BP.tablet}) {
    font-size: ${styledMap`
      big: ${Rem(44)};
      small: ${Rem(32)};
      default: ${Rem(36)};
    `};
    line-height: ${styledMap`
      big: ${Rem(45)};
      small: ${Rem(34)};
      default: ${Rem(38)};
  `};
  }

  em {
    font-style: normal;
    color: ${Colors.yellow};
  }
`;

export const StyledParagraph = styled.p`
  font-size: ${styledMap`
    big: ${Rem(24)};
    small: ${Rem(16)};
    default: ${Rem(20)};
    mini: ${Rem(16)};
  `};
  font-weight: 300;
  line-height: ${styledMap`
    big: ${Rem(30)};
    small: ${Rem(22)};
    mini: ${Rem(18)};
    default: ${Rem(26)};
  `};
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}
  ${({ justify }) => justify ? "text-align: justify;" : null}

  @media (${BP.tablet}) {
    font-size: ${styledMap`
      big: ${Rem(30)};
      small: ${Rem(20)};
      default: ${Rem(25)};
      mini: ${Rem(16)};
    `};
    line-height: ${styledMap`
      big: ${Rem(38)};
      small: ${Rem(28)};
      default: ${Rem(32)};
      mini: ${Rem(18)};
    `};
  }

  > a {
    text-decoration: none;
    ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}
  }

  & b {
    font-weight: bold;
  }
`;

export const StyledLabel2 = styled.p`
  font-size: ${Rem(10)};
  font-weight: 300;

  ${({ uppercase }) => uppercase ? "text-transform: uppercase;" : null}
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}

  @media (${BP.tablet}) {
    font-size: ${Rem(12)};
  }
`;

export const StyledLabel = styled.p`
  font-size: ${Rem(14)};
  font-weight: 300;
  line-height: 2;
  letter-spacing: ${Rem(4)};
  ${({ uppercase }) => uppercase ? "text-transform: uppercase;" : null}
  ${({ center }) => center ? "text-align: center;" : null}
  ${({ color }) => color ? `color: ${Colors[color] || "inherit"};` : null}

  @media (${BP.tablet}) {
    font-size: ${Rem(16)};
  }
`;

export const StyledButton = styled.button`
  appearance: none;
  display: inline-block;
  width: 100%;
  padding: ${Rem(10)} ${Rem(40)};
  background-color: ${styledMap`
    negative: ${Colors.white};
    outline: transparent;
    default: ${Colors.yellow}
  `};
  border: solid 3px;
  border-color: ${styledMap`
    negative: ${Colors.white};
    default: ${Colors.yellow}
  `};
  font-size: ${Rem(16)};
  font-weight: bold;
  line-height: ${Rem(22)};
  text-align: center;
  text-decoration: none;
  color: ${styledMap`
    negative: ${Colors.black};
    default: ${Colors.white}
  `};
  cursor: pointer;
  transition-property: background-color, border-color, color;
  transition-duration: .2s;

  @media (${BP.tablet}) {
    width: auto;
    font-size: ${Rem(20)};
    line-height: ${Rem(28)};
  }

  & + & {
    margin-top: ${Rem(24)};

    @media (${BP.tablet}) {
      margin-top: 0;
      margin-left: ${Rem(32)};
    }
  }

  &:hover {
    background-color: ${styledMap`
      negative: ${Colors.black};
      outline: ${Colors.white};
      default: transparent;
    `};
    border-color: ${styledMap`
      negative: ${Colors.black};
      outline: ${Colors.white};
      default: ${Colors.yellow}
    `};
    color: ${styledMap`
      negative: ${Colors.white};
      outline: ${Colors.black};
      default: ${Colors.black}
    `};
  }
`;

export const StyledLabelButton = styled.div`
  border-radius: ${Rem(24)};
  position: relative;
  appearance: none;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${Rem(16)};
  margin: 0 auto;
  border: none;
  box-shadow: none;
  font-family: ${Font.sans};
  font-weight: bold;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  height: ${Rem(40)};
  margin: ${Rem(5)};
`;

export const StyledContainer = styled.div`
  width: 100%;
  max-width: ${Rem(1488)};
  padding: 0 ${Rem(20)};
  overflow: visible;
  position: relative;
  margin: 0 auto;

  @media (${BP.tablet}) {
    padding: 0 ${Rem(32)};
    max-width: 1248px;
  }
`;

export const StyledSpacer = styled.div`
  height: ${({ size = 1 }) => Rem(size * 8)};
`;

export const StyledTextButtonBig = styled.div`
    font-size: 18px;
    padding: 5px;
    @media (${BP.tablet}) {
    font-size: 24px;
    }
`;

export const StyledStepText = styled.div`
  background-color: ${Colors.yellowClear};
  padding-top: ${Rem(15)};
  padding-left: ${Rem(25)};
  text-align: left;
  line-height: 1;
  justify-content: left;
  font-size: ${Rem(20)};
  font-weight: 600;
  color: ${Colors.yellow}; 
`;

const StyledSection = styled.section`
  ${({ padded }) => padded ? `padding: ${Rem(64)} 0;` : null}
  position: relative;
  z-index: 4;
  background-color: ${styledMap`
    yellow: #FFC700;
    grey: ${Colors.grayLight};
    default: transparent;
  `};

  @media (${BP.tablet}) {
    ${({ padded }) => padded ? `padding: ${Rem(104)} 0;` : null}
  }

  & > ${StyledContainer} {
    @media (${BP.tablet}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > *:nth-child(n+2) {
      margin-top: ${Rem(48)};

      @media (${BP.tablet}) {
        margin-top: 0;
        margin-left: ${Rem(80)};
      }
    }
  }
`;

StyledSection.Content = styled.div`
  flex-grow: 1;
  position: relative;
  z-index: 5;
`;

StyledSection.Visual = styled.figure`
  display: flex;

  max-width: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 5;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & > * {
    max-width: 100%;
  }
`;

export { StyledSection };
