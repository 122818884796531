import React, { useEffect, useState, useContext } from "react";
import {
  StyledCounter,
} from "./style";

import useCounter from "../../hooks/useCounter";
import { DataContext } from "../../contexts/DataContext";
import useApi from "../../hooks/useApi";

export default () => {
  const { labels, languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const [KPI, setKPI] = useState({
    users_count: 85267,
    total_sold_honey_kg: 16727,
    total_bees: 53454000,
    total_flowers: 33454000000,
  });
  const { getKPI } = useApi();
  /// 2 per second for 30 minute
  const counter = useCounter(2 * 60 * 30, 30 * 60 * 1000);

  useEffect(() => {
    getKPI().then((res) => {
      setKPI(res);
    });
  }, [getKPI]);

  const value = new Intl.NumberFormat(currentLanguageCode).format(KPI.total_bees*14 + counter);

  return (
    <StyledCounter>
      <div>
        <div type="number" style="
        position: relative;
        justify-self: center;
        ">
          <div style="text-align: left;  font-size: 20px; border-bottom: 2px solid;">
            {value}
          </div>
        </div >
        <div style=" font-size: 14px; font-weight: 10; text-align: left; padding-top: 5px;">
          {labels.PROTECTED_BEES}
        </div>
      </div>
      <div style="background-image: url('/2020/svg/ape.svg'); margin-left: 10px; width: 25px; background-position-y: center; background-repeat: no-repeat;" />
    </StyledCounter>
  );
};
